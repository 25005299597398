var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var visualAppealsLoadMore = true;
var lualBodyClasses = ["lualchristmas", "lual22", "lavenderappeal23", "PostCategory_light-up-a-life"];

$(document).ready(function () {

  if ($('body').hasClass('appeal-lualchristmas') || $('body').hasClass('appeal-lual22') || $('body').hasClass('PostCategory_light-up-a-life') || $('body').hasClass('lavenderappeal23')) {
    $(".appealTotaliser.totaliserPanel").insertBefore(".commentsList--printed");
  }
})

// temporarily hack totaliser

// if ($('body').hasClass('appeal-lual21')) {
//   $(".totaliserFigure.raisedAmount span").html("15.10");
// }

// Resize images for related products on product pages

if ($('body').hasClass('productPage')) {

  $('img.banner.productBanner').each(function () {
    var updatedSrc = $(this)
      .attr('src')
      .replace(/w=([0-9]*)&h=([0-9]*)/, 'w=300&h=300') // Non smart-cropped
      .replace(/width=([0-9]*)&height=([0-9]*)/, 'width=300&height=300'); // Smart cropped
    $(this).attr('src', updatedSrc);
  });

}

// IM-1262 Lavender Appeal 15.4.23
$(document).ready(function(){
  if ($('body').hasClass('appeal-lual22') || $('body').hasClass('appeal-lavenderappeal23')) {
    // move each .cta-button in .headerText after .donateBtn
    $('.headerText .cta-button').each(function () {
      $(this).addClass('button').insertAfter('.donateBtn');
    });
  }
})


//var lualBodyClasses = ["lavenderappeal2023"];

$(document).ready(function () {

  if ($('body').hasClass('appeal-lavenderappeal23')) {
    $(".appealTotaliser.totaliserPanel").insertBefore(".commentsList--printed");
  }
})